/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'

import SVG from './common/SVG'

const IconPearAssessmentFormerlyEdulastic = (props) => (
  <SVG
    width="395"
    height="89"
    viewBox="0 0 395 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_681_234)">
      <path
        d="M275.438 35.4415V9.6396H280.806L281.487 12.2215C283.269 10.3515 284.154 8.77783 289.162 8.77783C292.993 8.77783 295.844 10.2936 297.718 13.4137C299.145 10.9169 302.531 8.77783 307.252 8.77783C313.669 8.77783 317.767 13.1446 317.767 21.4318V35.4415H311.707V21.9666C311.707 16.888 309.837 14.3027 306.002 14.3027C301.724 14.3027 300.031 16.888 299.584 20.008V35.4449H293.525V21.97C293.525 16.7108 291.386 14.3061 287.911 14.3061C282.833 14.3061 281.494 18.0495 281.494 21.8814V35.4483H275.435V35.4415H275.438Z"
        fill="white"
      />
      <path
        d="M348.96 35.4415V9.6396H354.329L355.01 12.2215C356.951 10.4775 359.475 8.77783 363.931 8.77783C367.415 8.77783 370.767 10.4877 372.487 13.4137C373.819 15.6788 374.357 18.6217 374.357 21.6294V35.4449H368.297V21.97C368.297 16.7108 365.784 14.3061 361.969 14.3061C356.89 14.3061 355.017 18.0495 355.017 21.8814V35.4483H348.957V35.4415H348.96Z"
        fill="white"
      />
      <path
        d="M319.998 21.7895C319.998 14.2141 325.611 8.77783 333.544 8.77783C341.477 8.77783 347.179 14.3912 346.198 22.3242L345.932 24.5519H326.592L326.68 24.9095C327.392 28.0296 330.69 30.5229 334.344 30.5229C337.107 30.5229 340.05 29.4534 342.277 27.7605L345.663 31.7696C342.901 34.2663 339.515 35.9592 334.167 35.9592C325.257 35.9592 319.998 30.4344 319.998 21.7895ZM326.68 19.8275L340.05 19.9161V19.4699C340.05 16.6189 337.018 14.0336 333.544 14.0336C329.712 14.0336 327.127 16.796 326.772 19.2042L326.684 19.8275H326.68Z"
        fill="white"
      />
      <path
        d="M379.466 27.4027V15.1643H375.1V9.63944H379.466V0.119141H385.437V9.63944H392.924L392.836 15.1643H385.437V26.5988C385.437 29.0036 386.33 30.3422 388.469 30.3422C389.538 30.3422 390.697 30.0732 391.943 29.5418L394.171 34.2662C392.567 35.3357 390.162 35.959 387.399 35.959C382.944 35.959 379.466 33.1081 379.466 27.4027Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 35.4857H7.71161V23.9183H15.7741C23.22 23.9183 27.9546 18.7477 27.9546 12.0034C27.9546 5.25915 23.7479 0 16.2135 0H0V35.4857ZM7.71161 16.9969V7.00653H15.2461C18.7511 7.00653 20.4167 9.19671 20.4167 12C20.4167 14.8033 18.3151 16.9935 15.0724 16.9935H7.71161V16.9969Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.4071 35.9285C33.9086 35.9285 28.3872 29.9711 28.3872 22.3481C28.3872 14.2856 34.3446 8.76758 42.4071 8.76758C50.4695 8.76758 55.3744 14.9021 55.1122 22.0858L55.0236 24.3645H35.9217C35.9217 26.9055 38.2004 29.4465 42.8431 29.4465C45.6464 29.4465 48.0137 28.2203 49.9416 26.381L54.2368 30.5876C51.8695 33.4795 47.9285 35.9319 42.4071 35.9319V35.9285ZM36.3611 19.1054H48.0137C48.0137 17.4397 45.8235 15.161 42.1448 15.161C38.4661 15.161 36.3611 17.3512 36.3611 19.1054Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.2913 9.64297L77.8519 11.8331C76.4485 10.1675 73.4715 8.76758 70.4911 8.76758C61.5533 8.76758 57.6123 15.3381 57.6123 22.2595C57.6123 30.6694 63.1303 35.9285 70.3174 35.9285C73.5601 35.9285 76.4519 34.6137 77.7633 33.2138L78.2879 35.4925H85.2092V9.64297H78.2879H78.2913ZM77.501 22.2595C77.501 26.0267 75.1337 28.83 71.3665 28.83C67.5992 28.83 65.2319 26.0267 65.2319 22.2595C65.2319 18.4922 67.773 15.8627 71.3665 15.8627C74.96 15.8627 77.501 18.4037 77.501 22.2595Z"
        fill="white"
      />
      <path
        d="M89.7358 35.4891H97.4474V22.8726C97.4474 18.1414 99.3753 16.1249 102.352 16.1249C103.667 16.1249 104.805 16.5643 105.769 17.0889L108.572 10.7806C107.257 9.5544 105.592 8.76416 103.752 8.76416C100.51 8.76416 97.8834 11.0429 97.444 12.4428L96.7424 9.63955H89.7324V35.4891H89.7358Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.046 35.4413H125.665L128.795 26.231H143.639L147.038 35.4413H153.745L139.885 0.115723H132.017L119.05 35.4413H119.046ZM141.67 20.8662H130.672L135.144 7.18356L136.04 4.59145L136.844 7.18356L141.674 20.8662H141.67Z"
        fill="white"
      />
      <path
        d="M164.679 35.9965C160.833 35.9965 156.095 34.3854 153.769 32.2395L156.719 27.8591C159.045 29.5588 162.979 30.6317 165.571 30.6317C167.986 30.6317 169.952 29.8279 169.952 27.9477C169.952 26.9633 169.506 26.248 168.698 25.8018C167.894 25.3556 166.464 24.9979 164.407 24.7288L164.144 24.6914C159.706 24.0714 153.943 23.2676 153.943 17.3067C153.943 12.0306 158.146 8.72314 164.676 8.72314C168.698 8.72314 172.009 9.88465 174.87 11.7649L171.921 16.3258C170.132 15.1642 167.183 14.1799 164.41 14.1799C161.906 14.1799 159.938 14.8952 159.938 16.5948C159.938 17.2216 160.207 17.6678 160.742 18.114C161.188 18.5602 162.53 19.0098 164.676 19.2755C170.132 19.9023 175.943 20.8866 175.943 27.682C175.943 33.3158 170.579 35.9999 164.676 35.9999L164.679 35.9965Z"
        fill="white"
      />
      <path
        d="M177.023 32.2395C179.349 34.3854 184.087 35.9965 187.933 35.9965C193.836 35.9965 199.201 33.3124 199.201 27.6786C199.201 20.8832 193.386 19.8988 187.933 19.2721C185.787 19.003 184.445 18.5568 183.999 18.1106C183.461 17.6644 183.195 17.2148 183.195 16.5914C183.195 14.8917 185.164 14.1764 187.667 14.1764C190.44 14.1764 193.39 15.1608 195.178 16.3223L198.128 11.7615C195.266 9.88464 191.956 8.71973 187.933 8.71973C181.403 8.71973 177.2 12.0271 177.2 17.3033C177.2 23.2676 182.96 24.0714 187.402 24.6879L187.664 24.7254C189.721 24.9945 191.152 25.3521 191.956 25.7984C192.76 26.2446 193.209 26.9599 193.209 27.9443C193.209 29.8211 191.24 30.6283 188.829 30.6283C186.237 30.6283 182.299 29.5554 179.976 27.8557L177.026 32.2361L177.023 32.2395Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.321 35.9965C205.38 35.9965 200.104 30.4512 200.104 21.7791C200.104 14.1765 205.737 8.72314 213.698 8.72314C221.658 8.72314 227.38 14.357 226.396 22.3172L226.127 24.5517H206.722L206.81 24.9093C207.526 28.0396 210.833 30.5432 214.501 30.5432C217.274 30.5432 220.224 29.4702 222.462 27.7705L225.861 31.7933C223.088 34.2968 219.689 35.9965 214.324 35.9965H214.321ZM206.899 19.1835L206.81 19.8103L220.224 19.8988V19.4526C220.224 16.5914 217.182 13.9959 213.694 13.9959C209.849 13.9959 207.257 16.7686 206.899 19.1835Z"
        fill="white"
      />
      <path
        d="M227.55 32.2395C229.877 34.3854 234.615 35.9965 238.46 35.9965C244.363 35.9965 249.728 33.3124 249.728 27.6786C249.728 20.8832 243.914 19.8988 238.46 19.2721C236.314 19.003 234.972 18.5568 234.526 18.1106C233.988 17.6644 233.722 17.2148 233.722 16.5914C233.722 14.8917 235.691 14.1764 238.195 14.1764C240.967 14.1764 243.917 15.1608 245.705 16.3223L248.655 11.7615C245.794 9.88464 242.483 8.71973 238.46 8.71973C231.931 8.71973 227.727 12.0271 227.727 17.3033C227.727 23.2676 233.487 24.0714 237.929 24.6879L238.191 24.7254C240.249 24.9945 241.679 25.3521 242.483 25.7984C243.287 26.2446 243.737 26.9599 243.737 27.9443C243.737 29.8211 241.768 30.6283 239.356 30.6283C236.764 30.6283 232.826 29.5554 230.503 27.8557L227.554 32.2361H227.55V32.2395Z"
        fill="white"
      />
      <path
        d="M250.804 32.2395C253.131 34.3854 257.869 35.9965 261.714 35.9965C267.617 35.9965 272.982 33.3124 272.982 27.6786C272.982 20.8832 267.168 19.8988 261.714 19.2721C259.568 19.003 258.226 18.5568 257.78 18.1106C257.242 17.6644 256.976 17.2148 256.976 16.5914C256.976 14.8917 258.945 14.1764 261.449 14.1764C264.221 14.1764 267.171 15.1608 268.959 16.3223L271.909 11.7615C269.048 9.88464 265.737 8.71973 261.714 8.71973C255.185 8.71973 250.981 12.0271 250.981 17.3033C250.981 23.2676 256.741 24.0714 261.183 24.6879L261.445 24.7254C263.502 24.9945 264.933 25.3521 265.737 25.7984C266.541 26.2446 266.99 26.9599 266.99 27.9443C266.99 29.8211 265.022 30.6283 262.61 30.6283C260.018 30.6283 256.08 29.5554 253.757 27.8557L250.808 32.2361H250.804V32.2395Z"
        fill="white"
      />
    </g>
    <path
      d="M251.227 68.8242C250.605 68.8242 249.996 68.9648 249.398 69.2461C248.812 69.5156 248.291 69.9023 247.834 70.4062C247.377 70.9102 247.014 71.5137 246.744 72.2168C246.486 72.9199 246.357 73.7051 246.357 74.5723C246.357 75.4395 246.486 76.2305 246.744 76.9453C247.014 77.6484 247.377 78.252 247.834 78.7559C248.291 79.2598 248.812 79.6523 249.398 79.9336C249.996 80.2031 250.605 80.3379 251.227 80.3379C251.754 80.3379 252.252 80.2441 252.721 80.0566C253.189 79.8574 253.605 79.6055 253.969 79.3008C254.344 78.9844 254.66 78.6211 254.918 78.2109C255.188 77.8008 255.398 77.3672 255.551 76.9102H258.24C258.053 77.7305 257.754 78.5039 257.344 79.2305C256.934 79.9453 256.424 80.5723 255.814 81.1113C255.205 81.6504 254.508 82.0781 253.723 82.3945C252.949 82.6992 252.117 82.8516 251.227 82.8516C250.195 82.8516 249.217 82.6523 248.291 82.2539C247.365 81.8438 246.557 81.2754 245.865 80.5488C245.186 79.8105 244.646 78.9375 244.248 77.9297C243.861 76.9219 243.668 75.8027 243.668 74.5723C243.668 73.3535 243.861 72.2402 244.248 71.2324C244.646 70.2246 245.186 69.3574 245.865 68.6309C246.557 67.8926 247.365 67.3184 248.291 66.9082C249.217 66.498 250.195 66.293 251.227 66.293C252.117 66.293 252.949 66.4512 253.723 66.7676C254.508 67.0723 255.205 67.5 255.814 68.0508C256.424 68.5898 256.934 69.2168 257.344 69.9316C257.754 70.6465 258.053 71.4141 258.24 72.2344H255.551C255.41 71.7773 255.205 71.3496 254.936 70.9512C254.666 70.541 254.344 70.1777 253.969 69.8613C253.605 69.5449 253.189 69.293 252.721 69.1055C252.252 68.918 251.754 68.8242 251.227 68.8242Z"
      fill="white"
    />
    <path
      d="M240.064 82.6758H237.357V66.4688H240.064V82.6758ZM237.006 61.8984C237.006 61.3945 237.164 60.9844 237.48 60.668C237.809 60.3398 238.213 60.1758 238.693 60.1758C239.197 60.1758 239.607 60.3398 239.924 60.668C240.252 60.9844 240.416 61.3945 240.416 61.8984C240.416 62.3789 240.252 62.7832 239.924 63.1113C239.607 63.4395 239.197 63.6035 238.693 63.6035C238.213 63.6035 237.809 63.4395 237.48 63.1113C237.164 62.7832 237.006 62.3789 237.006 61.8984Z"
      fill="white"
    />
    <path
      d="M227.443 62.1621H229.781V66.4688H233.385V69H229.781V77.4551C229.781 78.3691 230.027 79.0781 230.52 79.582C231.012 80.0859 231.668 80.3379 232.488 80.3379C232.723 80.3379 232.939 80.3145 233.139 80.2676C233.232 80.2441 233.314 80.209 233.385 80.1621V82.5C233.268 82.5703 233.139 82.6289 232.998 82.6758C232.682 82.793 232.271 82.8516 231.768 82.8516C231.146 82.8516 230.555 82.7227 229.992 82.4648C229.43 82.1953 228.932 81.8262 228.498 81.3574C228.064 80.8887 227.719 80.3262 227.461 79.6699C227.215 79.0137 227.092 78.2754 227.092 77.4551V69H224.209V66.4688H225.475C226.799 66.4688 227.455 65.8125 227.443 64.5V62.1621Z"
      fill="white"
    />
    <path
      d="M222.24 78C222.24 78.668 222.105 79.2949 221.836 79.8809C221.578 80.4668 221.209 80.9824 220.729 81.4277C220.248 81.873 219.668 82.2246 218.988 82.4824C218.309 82.7285 217.535 82.8516 216.668 82.8516C214.863 82.8516 213.445 82.3711 212.414 81.4102C211.383 80.4492 210.82 79.2539 210.727 77.8242H213.434C213.527 78.5391 213.838 79.1367 214.365 79.6172C214.893 80.0977 215.66 80.3379 216.668 80.3379C217.582 80.3379 218.291 80.1035 218.795 79.6348C219.299 79.166 219.551 78.6211 219.551 78C219.551 77.4023 219.34 76.9512 218.918 76.6465C218.496 76.3418 217.975 76.1016 217.354 75.9258C216.732 75.75 216.053 75.5859 215.314 75.4336C214.588 75.2695 213.908 75.0352 213.275 74.7305C212.654 74.4141 212.133 73.9688 211.711 73.3945C211.289 72.8086 211.078 72 211.078 70.9688C211.078 70.3477 211.207 69.7559 211.465 69.1934C211.734 68.6309 212.104 68.1328 212.572 67.6992C213.041 67.2656 213.604 66.9258 214.26 66.6797C214.928 66.4219 215.672 66.293 216.492 66.293C218.215 66.293 219.551 66.7441 220.5 67.6465C221.449 68.5488 221.971 69.7207 222.064 71.1621H219.357C219.264 70.4473 218.988 69.8789 218.531 69.457C218.074 69.0352 217.395 68.8242 216.492 68.8242C215.672 68.8242 215.016 69.0469 214.523 69.4922C214.031 69.9258 213.785 70.418 213.785 70.9688C213.785 71.5664 213.996 72.0176 214.418 72.3223C214.84 72.627 215.361 72.873 215.982 73.0605C216.604 73.2363 217.277 73.4004 218.004 73.5527C218.742 73.7051 219.422 73.9395 220.043 74.2559C220.676 74.5605 221.197 75.0117 221.607 75.6094C222.029 76.1953 222.24 76.9922 222.24 78Z"
      fill="white"
    />
    <path
      d="M201.006 66.293C201.896 66.293 202.717 66.4453 203.467 66.75C204.229 67.043 204.879 67.4414 205.418 67.9453C205.957 68.4492 206.373 69.041 206.666 69.7207C206.971 70.3887 207.123 71.1094 207.123 71.8828V82.6758H205.154L204.434 80.8828C204.07 81.2695 203.66 81.6035 203.203 81.8848C202.793 82.1543 202.289 82.3828 201.691 82.5703C201.094 82.7578 200.385 82.8516 199.564 82.8516C198.744 82.8516 198.006 82.7285 197.35 82.4824C196.693 82.2246 196.131 81.873 195.662 81.4277C195.193 80.9824 194.824 80.4668 194.555 79.8809C194.297 79.2949 194.168 78.668 194.168 78C194.168 77.2266 194.32 76.5234 194.625 75.8906C194.941 75.2578 195.48 74.707 196.242 74.2383C197.016 73.7695 198.07 73.4121 199.406 73.166C200.742 72.9082 202.418 72.7793 204.434 72.7793V71.8828C204.434 71.0625 204.111 70.3477 203.467 69.7383C202.834 69.1289 202.014 68.8242 201.006 68.8242C199.998 68.8242 199.254 69.0352 198.773 69.457C198.293 69.8672 197.959 70.4355 197.771 71.1621H194.889C194.982 70.4941 195.188 69.8672 195.504 69.2812C195.82 68.6836 196.236 68.1621 196.752 67.7168C197.268 67.2715 197.877 66.9258 198.58 66.6797C199.283 66.4219 200.092 66.293 201.006 66.293ZM199.564 80.3379C200.279 80.3379 200.936 80.2266 201.533 80.0039C202.143 79.7695 202.658 79.4766 203.08 79.125C203.502 78.7617 203.83 78.3574 204.064 77.9121C204.311 77.4668 204.434 77.0156 204.434 76.5586V75.293C202.805 75.293 201.498 75.3633 200.514 75.5039C199.529 75.6328 198.762 75.8262 198.211 76.084C197.672 76.3301 197.314 76.6289 197.139 76.9805C196.963 77.3203 196.875 77.7188 196.875 78.1758C196.875 78.7266 197.115 79.2246 197.596 79.6699C198.088 80.1152 198.744 80.3379 199.564 80.3379Z"
      fill="white"
    />
    <path d="M190.037 82.6758H187.33V60H190.037V82.6758Z" fill="white" />
    <path
      d="M175.113 82.8516C174.246 82.8516 173.432 82.6816 172.67 82.3418C171.92 81.9902 171.252 81.5098 170.666 80.9004C170.092 80.2793 169.635 79.541 169.295 78.6855C168.967 77.8184 168.803 76.8691 168.803 75.8379V66.4688H171.51V75.8379C171.51 76.5059 171.609 77.1152 171.809 77.666C172.02 78.2168 172.301 78.6914 172.652 79.0898C173.016 79.4883 173.438 79.7988 173.918 80.0215C174.398 80.2324 174.914 80.3379 175.465 80.3379C176.742 80.3379 177.779 79.9336 178.576 79.125C179.385 78.3047 179.789 77.209 179.789 75.8379V66.4688H182.479V82.6758H180.51L179.789 80.8828C179.449 81.2695 179.051 81.6035 178.594 81.8848C178.184 82.1543 177.691 82.3828 177.117 82.5703C176.555 82.7578 175.887 82.8516 175.113 82.8516Z"
      fill="white"
    />
    <path
      d="M161.596 74.5723C161.596 73.7051 161.455 72.9199 161.174 72.2168C160.904 71.5137 160.541 70.9102 160.084 70.4062C159.627 69.9023 159.088 69.5156 158.467 69.2461C157.857 68.9648 157.219 68.8242 156.551 68.8242C155.883 68.8242 155.238 68.9648 154.617 69.2461C154.008 69.5156 153.475 69.9023 153.018 70.4062C152.561 70.9102 152.191 71.5137 151.91 72.2168C151.641 72.9199 151.506 73.7051 151.506 74.5723C151.506 75.4395 151.641 76.2305 151.91 76.9453C152.191 77.6484 152.561 78.252 153.018 78.7559C153.475 79.2598 154.008 79.6523 154.617 79.9336C155.238 80.2031 155.883 80.3379 156.551 80.3379C157.219 80.3379 157.857 80.2031 158.467 79.9336C159.088 79.6523 159.627 79.2598 160.084 78.7559C160.541 78.252 160.904 77.6484 161.174 76.9453C161.455 76.2305 161.596 75.4395 161.596 74.5723ZM161.227 80.8828C160.863 81.2695 160.459 81.6035 160.014 81.8848C159.604 82.1543 159.094 82.3828 158.484 82.5703C157.887 82.7578 157.184 82.8516 156.375 82.8516C155.344 82.8516 154.365 82.6523 153.439 82.2539C152.514 81.8438 151.705 81.2754 151.014 80.5488C150.334 79.8105 149.795 78.9375 149.396 77.9297C149.01 76.9219 148.816 75.8027 148.816 74.5723C148.816 73.3535 149.01 72.2402 149.396 71.2324C149.795 70.2246 150.334 69.3574 151.014 68.6309C151.705 67.8926 152.514 67.3184 153.439 66.9082C154.365 66.498 155.344 66.293 156.375 66.293C157.195 66.293 157.904 66.4102 158.502 66.6445C159.1 66.8672 159.604 67.1133 160.014 67.3828C160.471 67.6992 160.875 68.0566 161.227 68.4551V60H163.934V82.6758H161.947L161.227 80.8828Z"
      fill="white"
    />
    <path
      d="M145.758 80.1621V82.6758H130.992V60H145.389V62.5137H133.875V69.7207H144.492V72.2344H133.875V80.1621H145.758Z"
      fill="white"
    />
    <path
      d="M104.52 66.4688H107.402L112.271 78.8965L117.123 66.4688H120.006L112.992 84.1172C112.348 85.7461 111.668 86.9004 110.953 87.5801C110.25 88.2715 109.371 88.6172 108.316 88.6172C108.176 88.6172 108.041 88.6113 107.912 88.5996C107.795 88.5879 107.678 88.5703 107.561 88.5469C107.443 88.5234 107.332 88.4883 107.227 88.4414V86.2793C108.047 86.2793 108.656 86.0977 109.055 85.7344C109.465 85.3711 109.816 84.832 110.109 84.1172L110.83 82.3242L104.52 66.4688Z"
      fill="white"
    />
    <path d="M101.654 82.6758H98.9473V60H101.654V82.6758Z" fill="white" />
    <path
      d="M95.7129 69H94.9922C93.7148 69 92.6777 69.4102 91.8809 70.2305C91.084 71.0391 90.6855 72.1289 90.6855 73.5V82.6758H87.9785V66.4688H89.9648L90.6855 68.2793C91.0254 67.916 91.418 67.5938 91.8633 67.3125C92.2734 67.043 92.7656 66.8086 93.3398 66.6094C93.9141 66.3984 94.5879 66.293 95.3613 66.293H95.7129V69Z"
      fill="white"
    />
    <path
      d="M84.041 78.3516C83.7715 78.9258 83.4316 79.4941 83.0215 80.0566C82.6113 80.6074 82.1309 81.0879 81.5801 81.498C81.0293 81.8965 80.4023 82.2246 79.6992 82.4824C79.0078 82.7285 78.2344 82.8516 77.3789 82.8516C76.3477 82.8516 75.3691 82.6523 74.4434 82.2539C73.5176 81.8438 72.709 81.2754 72.0176 80.5488C71.3379 79.8105 70.7988 78.9375 70.4004 77.9297C70.0137 76.9219 69.8203 75.8027 69.8203 74.5723C69.8203 73.3535 70.0137 72.2402 70.4004 71.2324C70.7988 70.2246 71.332 69.3574 72 68.6309C72.6797 67.8926 73.4707 67.3184 74.373 66.9082C75.2754 66.498 76.2188 66.293 77.2031 66.293C78.1875 66.293 79.1133 66.4863 79.9805 66.873C80.8594 67.2598 81.6211 67.8105 82.2656 68.5254C82.9219 69.2285 83.4375 70.0781 83.8125 71.0742C84.1992 72.0703 84.3926 73.1777 84.3926 74.3965C84.3926 74.6309 84.3809 74.8359 84.3574 75.0117C84.334 75.1758 84.3105 75.3164 84.2871 75.4336C84.2637 75.5742 84.2402 75.709 84.2168 75.8379H72.7031C72.75 76.4121 72.9082 76.9746 73.1777 77.5254C73.459 78.0762 73.8047 78.5566 74.2148 78.9668C74.6367 79.377 75.123 79.7109 75.6738 79.9688C76.2363 80.2148 76.8047 80.3379 77.3789 80.3379C77.8828 80.3379 78.3281 80.2676 78.7148 80.127C79.1133 79.9746 79.4766 79.8047 79.8047 79.6172C80.1328 79.4297 80.4023 79.2246 80.6133 79.002C80.8359 78.7676 81.0176 78.5508 81.1582 78.3516H84.041ZM77.2031 68.8242C76.6523 68.8242 76.125 68.9414 75.6211 69.1758C75.1289 69.4102 74.6777 69.7324 74.2676 70.1426C73.8691 70.5527 73.5293 71.0449 73.248 71.6191C72.9785 72.1934 72.7969 72.8203 72.7031 73.5H81.5098C81.3223 72.0586 80.8242 70.9219 80.0156 70.0898C79.2188 69.2461 78.2812 68.8242 77.2031 68.8242Z"
      fill="white"
    />
    <path
      d="M60.9961 66.293C61.7109 66.293 62.3965 66.4453 63.0527 66.75C63.709 67.043 64.2891 67.4824 64.793 68.0684C65.2969 68.6426 65.6895 69.3457 65.9707 70.1777C66.2637 70.998 66.4102 71.9238 66.4102 72.9551V82.6758H63.7031V72.9551C63.7031 71.584 63.3926 70.5527 62.7715 69.8613C62.1504 69.1699 61.3828 68.8242 60.4688 68.8242C59.5547 68.8242 58.752 69.1934 58.0605 69.9316C57.3809 70.6699 57.041 71.6777 57.041 72.9551V82.6758H54.3516V72.9551C54.3516 71.584 54.0352 70.5527 53.4023 69.8613C52.7812 69.1699 52.0137 68.8242 51.0996 68.8242C50.666 68.8242 50.2383 68.9238 49.8164 69.123C49.4062 69.3223 49.043 69.6094 48.7266 69.9844C48.4102 70.3594 48.1582 70.7988 47.9707 71.3027C47.7832 71.8066 47.6895 72.3574 47.6895 72.9551V82.6758H44.9824V66.4688H46.9688L47.6895 68.2793C47.959 67.916 48.2812 67.5938 48.6562 67.3125C48.9961 67.043 49.4062 66.8086 49.8867 66.6094C50.3672 66.3984 50.9531 66.293 51.6445 66.293C52.4648 66.293 53.1562 66.4453 53.7188 66.75C54.2812 67.043 54.7441 67.3711 55.1074 67.7344C55.541 68.168 55.8867 68.6484 56.1445 69.1758C56.4609 68.6484 56.8477 68.168 57.3047 67.7344C57.7148 67.3711 58.2129 67.043 58.7988 66.75C59.3965 66.4453 60.1289 66.293 60.9961 66.293Z"
      fill="white"
    />
    <path
      d="M41.9238 69H41.2031C39.9258 69 38.8887 69.4102 38.0918 70.2305C37.2949 71.0391 36.8965 72.1289 36.8965 73.5V82.6758H34.1895V66.4688H36.1758L36.8965 68.2793C37.2363 67.916 37.6289 67.5938 38.0742 67.3125C38.4844 67.043 38.9766 66.8086 39.5508 66.6094C40.125 66.3984 40.7988 66.293 41.5723 66.293H41.9238V69Z"
      fill="white"
    />
    <path
      d="M18.1582 74.5723C18.1582 75.4395 18.2871 76.2305 18.5449 76.9453C18.8145 77.6484 19.1777 78.252 19.6348 78.7559C20.0918 79.2598 20.6133 79.6523 21.1992 79.9336C21.7969 80.2031 22.4062 80.3379 23.0273 80.3379C23.6484 80.3379 24.252 80.2031 24.8379 79.9336C25.4238 79.6523 25.9395 79.2598 26.3848 78.7559C26.8301 78.252 27.1875 77.6484 27.457 76.9453C27.7383 76.2305 27.8789 75.4395 27.8789 74.5723C27.8789 73.7051 27.7383 72.9199 27.457 72.2168C27.1875 71.5137 26.8301 70.9102 26.3848 70.4062C25.9395 69.9023 25.4238 69.5156 24.8379 69.2461C24.252 68.9648 23.6484 68.8242 23.0273 68.8242C22.4062 68.8242 21.7969 68.9648 21.1992 69.2461C20.6133 69.5156 20.0918 69.9023 19.6348 70.4062C19.1777 70.9102 18.8145 71.5137 18.5449 72.2168C18.2871 72.9199 18.1582 73.7051 18.1582 74.5723ZM30.5859 74.5723C30.5859 75.791 30.3867 76.9102 29.9883 77.9297C29.5898 78.9375 29.0508 79.8105 28.3711 80.5488C27.6914 81.2754 26.8887 81.8438 25.9629 82.2539C25.0371 82.6523 24.0586 82.8516 23.0273 82.8516C21.9961 82.8516 21.0176 82.6523 20.0918 82.2539C19.166 81.8438 18.3574 81.2754 17.666 80.5488C16.9863 79.8105 16.4473 78.9375 16.0488 77.9297C15.6621 76.9219 15.4688 75.8027 15.4688 74.5723C15.4688 73.3535 15.6621 72.2402 16.0488 71.2324C16.4473 70.2246 16.9863 69.3574 17.666 68.6309C18.3574 67.8926 19.166 67.3184 20.0918 66.9082C21.0176 66.498 21.9961 66.293 23.0273 66.293C24.0586 66.293 25.0371 66.498 25.9629 66.9082C26.8887 67.3184 27.6914 67.8926 28.3711 68.6309C29.0508 69.3574 29.5898 70.2246 29.9883 71.2324C30.3867 72.2402 30.5859 73.3535 30.5859 74.5723Z"
      fill="white"
    />
    <path
      d="M2.88281 62.5137V70.0723H11.8828V72.6035H2.88281V82.6758H0V60H13.8516V62.5137H2.88281Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_681_234">
        <rect width="394.171" height="36" fill="white" />
      </clipPath>
    </defs>
  </SVG>
)

export default withIconStyles(IconPearAssessmentFormerlyEdulastic)
